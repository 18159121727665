import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>108 Coming Soon</title>
        <meta property="og:title" content="108 Coming Soon" />
      </Helmet>
      <div className="home-homepage">
        <div className="home-group">
          <img
              src="assets/logo-full-blue.svg"
              alt="108 coming soon"
              className="home-number-logo"
            />
          <span className="home-text">
            <span>
              {' '}
              Coming soon
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <div className="home-animation">
            <video controls autoPlay muted playsInline>
              <source src="assets/home-video.mp4" type="video/mp4"></source>
            </video>
        </div>
        <div class="home-smaller-text">
          Be rewarded for your wellness
        </div>
        <div className="home-contact">
          <a className="contact-button" href="mailto:contact@108.io">
            Get in contact
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
