import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Home from './views/home'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Home} exact path="/" />
        
        <Route path='/whitepaper' component={() => {
            window.location.href = 'https://108.gitbook.io/108-whitepaper/';
            return null;
        }}/>
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
